import React from 'react';
import { Helmet } from 'react-helmet';
import SEO from '../components/seo';
import { Widget } from '@typeform/embed-react';

const DirectorSetupPage = () => (
  <>
    <SEO title='Director Setup' />
    <Helmet>
      <body style={{ overflow: `hidden`, background: `var(--blue1)` }} />
    </Helmet>
    <Widget id='F6ehlUz1' fullScreen={true} />
  </>
);

export default DirectorSetupPage;
